import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './App';
import router from './router';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import store from '@/store';
import { registerModules } from "./register-modules";
import profileModule from "@/modules/profile";
import authModule from "@/modules/auth";
import serviceModule from "@/modules/services";
import menu from "@/menu";

Vue.config.productionTip = false;

// Bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);

store.commit("menu/setMenu", menu);
registerModules({
    profile: profileModule,
    auth: authModule,
    services: serviceModule
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});
