export default [
    {
        name: "Сервисы",
        icon: "tools",
        children: []
    },
    {
        name: "Профиль",
        icon: "person-fill",
        path: "/profile",
        children: []
    }
]