import Module from "@/modules/services/Module";
import ServicePage from "@/modules/services/views/ServicePage";

export default {
    path: "/service",
    component: Module,
    children: [
        {
            path: 'news',
            component: ServicePage,
            meta: {
                title: 'Новости'
            },
            props: {
                url: process.env.VUE_APP_NEWS_UI_URL
            }
        },
        {
            path: 'settings',
            component: ServicePage,
            meta: {
                title: 'Настройки'
            },
            props: {
                url: process.env.VUE_APP_SETTINGS_UI_URL
            }
        }
    ]
};