<template>
  <div class="page-margin">
    <b-container class="mb-4">
      <div class="text-center mt-5">
        <h6 class="display-4">Профиль</h6>
      </div>
      <b-card class="mt-5" header-class="header-font" header-text-variant="primary" header="Личная информация">
        <b-form @submit.prevent>
          <b-form-group label="Логин">
            <b-form-input v-model="userData.login" :readonly="true"/>
          </b-form-group>
          <b-form-group label="Электронная почта">
            <b-form-input v-model="$v.userData.email.$model"
                          :state="$v.userData.email.$dirty ? !$v.userData.email.$error : null"
                          aria-describedby="email-validation"/>
            <b-form-invalid-feedback id="email-validation">
              Некорректный адрес электронной почты
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button variant="primary" class="mt-2 mx-auto form-control" type="submit" @click="saveProfile"
                    :disabled="submitting || $v.userData.email.$anyError">
            Сохранить
          </b-button>
        </b-form>
      </b-card>
      <b-card header-class="header-font" header-text-variant="primary" class="mt-5" header="Смена пароля">
        <password-change @changed="updatePassword"/>
        <b-alert class="mt-4" variant="danger" :show="incorrectOldPassword" dismissible
                 @dismissed="incorrectOldPassword=false">
          Неверно введён старый пароль
        </b-alert>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import PasswordChange from "@/components/PasswordChange";
import userService from "@/modules/profile/api/service/user-service";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "profile",
  components: {
    'password-change': PasswordChange
  },
  created() {
    document.title = this.$route.meta.title;
    this.fetchUserInfo();
  },
  data() {
    return {
      incorrectOldPassword: false,
      submitting: false,
      userData: {
        login: null,
        email: null,
        services: [
          {
            name: null,
            description: null
          }
        ]
      }
    }
  },
  validations() {
    return {
      userData: {
        email: {
          pattern: (value) => new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
        }
      }
    }
  },
  methods: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapActions("auth", ["refreshToken", "changePassword"]),
    fetchUserInfo() {
      return userService.getUserInfo()
          .then(response => {
            this.userData = response.data;
            return Promise.resolve();
          })
          .catch(async err => {
            if (err.response?.status !== 401) {
              this.$bvToast.toast('Не удалось загрузить личные данные', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            } else {
              await this.refreshToken()
                  .then(async () => {
                    await userService.getUserInfo()
                        .then(response => {
                          this.userData = response.data;
                          return Promise.resolve();
                        })
                        .catch(() => {
                          this.$bvToast.toast('Не удалось загрузить личные данные', {
                            title: 'Ошибка',
                            autoHideDelay: 5000,
                            appendToast: true
                          });
                          return Promise.reject();
                        });
                    return Promise.resolve();
                  })
                  .catch(() => {
                    this.$router.push("/login");
                  });
            }
            return Promise.reject();
          });
    },
    saveProfile() {
      this.submitting = true;
      userService.updateUserInfo({email: this.userData.email})
          .then(() => {
            this.$bvToast.toast('Данные успешно обновлены', {
              variant: 'success',
              solid: true,
              noCloseButton: true
            });
          })
          .catch(err => {
            if (err.response?.status !== 401) {
              this.$bvToast.toast('Не удалось обновить личные данные', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            } else {
              this.refreshToken()
                  .then(() => {
                    userService.updateUserInfo({email: this.userData.email})
                        .then(() => {
                          this.$bvToast.toast('Данные успешно обновлены', {
                            variant: 'success',
                            solid: true,
                            noCloseButton: true
                          });
                        })
                        .catch(() => {
                          this.$bvToast.toast('Не удалось обновить личные данные', {
                            title: 'Ошибка',
                            autoHideDelay: 5000,
                            appendToast: true
                          });
                        })
                  })
                  .catch(() => {
                    this.$router.push("/login");
                  });
            }
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    updatePassword(result) {
      this.changePassword({
        login: this.userData.login,
        password: result.oldPassword,
        newPassword: result.newPassword
      }).then(() => {
        this.incorrectOldPassword = false;
        this.$bvToast.toast('Пароль успешно сменён', {
          variant: 'success',
          solid: true,
          noCloseButton: true
        });
      }).catch(err => {
        if (err.response?.status === 403) {
          this.incorrectOldPassword = true;
        } else {
          this.$bvToast.toast('Не удалось сменить пароль', {
            title: 'Ошибка',
            autoHideDelay: 5000,
            appendToast: true
          });
        }
      });
    }
  }
}
</script>
<style scoped>
.header-font {
  font-size: 22px;
}
</style>