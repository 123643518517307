<template>
  <div>
    <b-sidebar v-if="isAuthorized" width="13%" id="sidebar-no-header" aria-labelledby="sidebar-no-header-title"
               shadow visible noCloseOnRouteChange>
      <template #header="{ hide }">
        <div class="px-2 py-1 border-bottom w-100">
          <h4 style="color: #4285F4; font-weight: bold; font-size: 28px">Меню</h4>
        </div>
      </template>
      <div class="mt-3">
        <menu-item v-for="item in getMenu" :key="item.name"
                   class="border-bottom mb-1"
                   :icon="item.icon" :name="item.name"
                   :children="item.children" :path="item.path"/>
      </div>
      <template #footer="{ hide }">
        <div class="px-3 py-3">
          <b-button variant="danger" block @click="logoutClicked">Выйти</b-button>
        </div>
      </template>
    </b-sidebar>
    <router-view />
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import MenuItem from "@/components/MenuItem";

export default {
  name: "app",
  components: {
    "menu-item": MenuItem
  },
  mounted() {
    document.title = "Личный кабинет";
    window.addEventListener("message", (event) => {
      if (event.data === "LOGOUT") {
        this.setAuthorized(false);
        this.$router.push("/login");
      }
    }, false);
  },
  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("menu", ["getMenu"])
  },
  methods: {
    ...mapMutations("auth", ["setAuthorized"]),
    ...mapActions("auth", ["logout"]),
    logoutClicked() {
      this.logout()
          .then(() => {
            this.$router.push("/login");
          })
          .catch(err => {
            if (err.response?.status === 401) {
              this.$router.push("/login");
            }
          });
    }
  }
};
</script>
<style>
.page-margin {
  margin-left: 13%;
}
</style>