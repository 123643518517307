<template>
  <div>
    <iframe class="iframe" :src='url'>
    </iframe>
  </div>
</template>
<script>
export default {
  name: 'service-page',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  created() {
    document.title = this.$route.meta.title;
  },
  updated() {
    document.title = this.$route.meta.title;
  }
};
</script>
<style scoped>
.iframe {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 87%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
  height: 100%;
}
</style>