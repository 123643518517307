export default [
    {
        name: "Сервисы",
        children: [
            {
                name: "Новости",
                icon: 'b-icon-newspaper',
                path: "/service/news"
            },
            {
                name: "Настройки",
                icon: 'b-icon-gear',
                path: "/service/settings"
            }
        ]
    }
]