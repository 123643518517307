import auth from '@/modules/auth/api/service/auth-service';

export default {
    namespaced: true,
    state: {
        isAuthorized: null
    },
    actions: {
        refreshToken({commit}) {
            return auth.refreshToken()
                .then(async () => {
                    commit("setAuthorized", true);
                })
                .catch(err => {
                    commit("setAuthorized", false);
                    throw err;
                });
        },
        login({dispatch, commit}, payload) {
            return auth.login(payload.login, payload.password)
                .then(async response => {
                    if (response.data.changePassword !== true) {
                        commit("setAuthorized", true);
                    }
                    return Promise.resolve(response);
                })
                .catch(err => {
                    commit("setAuthorized", false);
                    throw err;
                });
        },
        logout({commit}) {
            return auth.logout()
                .then(() => {
                    commit("setAuthorized", false);
                })
                .catch(err => {
                    if (err.response?.status === 401) {
                        commit("setAuthorized", false);
                    }
                    throw err;
                });
        },
        changePassword({}, payload) {
            return auth.changePassword(payload.login, payload.password, payload.newPassword);
        }
    },
    mutations: {
        setAuthorized(state, isAuthorized) {
            state.isAuthorized = isAuthorized;
        }
    },
    getters: {
        isAuthorized(state) {
            return state.isAuthorized;
        }
    }
};
