import Vue from 'vue';
import Router from 'vue-router';
import store from "@/store";
import pathUtils from "@/util/path-utils";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            redirect: '/profile'
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters["auth/isAuthorized"] == null) {
        await store.dispatch("auth/refreshToken")
            .catch(() => {});
    }

    if (!store.getters["auth/isAuthorized"] && !pathUtils.pathsEqual('/login', to.path)) {
        return next('/login');
    }
    return next();
});

export default router;
