<template>
  <div>
    <div>
      <a><img class="mt-4 ml-4" alt="Логотип" src="@/assets/logo.png"></a>
    </div>
    <b-container class="pt-5">
      <div class="text-center mt-5">
        <h2 class="display-4">Личный кабинет</h2>
      </div>
      <b-card title="Вход"
              class="mt-4 text-center block w-50"
              style="margin: auto">
        <b-form @submit.prevent="onSubmit" method="post">
          <b-input-group class="mt-1">
            <b-input-group-prepend is-text>
              <b-icon icon="person-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input v-model="username" placeholder="Логин" required="required"/>
          </b-input-group>
          <b-input-group class="mt-1">
            <b-input-group-prepend is-text>
              <b-icon icon="lock-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input v-model="password" placeholder="Пароль" type="password" required="required"/>
          </b-input-group>
          <b-button type="submit" variant="primary" class="mt-3 mx-auto form-control">Войти</b-button>
          <b-alert class="mt-4" variant="danger" :show="errorMessage != null" dismissible
                   @dismissed="errorMessage=null">
            {{ errorMessage }}
          </b-alert>
        </b-form>
      </b-card>
    </b-container>
    <b-modal v-model="needChangePassword" hide-footer hide-header no-close-on-backdrop>
      <h4>Пожалуйста, смените пароль</h4>
      <password-change class="mt-4" :previous-password="password" @changed="updatePassword"/>
    </b-modal>
  </div>
</template>
<script>
import PasswordChange from "@/components/PasswordChange";
import {mapActions} from "vuex";

export default {
  name: 'login',
  components: {
    PasswordChange
  },
  created() {
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      username: '',
      password: '',
      errorMessage: null,
      needChangePassword: false
    }
  },
  methods: {
    ...mapActions("auth", ["login", "changePassword"]),
    onSubmit() {
      this.errorMessage = null;
      this.login({
        login: this.username,
        password: this.password
      }).then(response => {
        if (response.data?.changePassword === true) {
          this.needChangePassword = true;
        } else {
          this.$router.push('/profile');
        }
      }).catch(err => {
        if (err.response != null) {
          if (err.response.status === 403) {
            this.errorMessage = 'Введён неверный логин или пароль';
          } else {
            this.errorMessage = `Произошла ошибка при авторизации (код ${err.response.status})`;
          }
        } else {
          this.errorMessage = 'Произошла ошибка при авторизации';
        }
      });
      return false;
    },
    updatePassword(result) {
      this.changePassword({
        login: this.username,
        password: this.password,
        newPassword: result.newPassword
      }).then(() => {
        this.login({
          login: this.username,
          password: result.newPassword
        }).then(() => {
          this.$router.push('/profile');
        }).catch(() => {
          this.$bvToast.toast('Не удалось авторизоваться после смены пароля', {
            title: 'Ошибка',
            autoHideDelay: 5000,
            appendToast: true
          });
        }).finally(() => {
          this.needChangePassword = false;
        });
      }).catch(() => {
        this.$bvToast.toast('Не удалось сменить пароль', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true
        });
      });
    }
  }
}
</script>
