import {AUTH_AXIOS} from '@/modules/auth/api/backend-config';

export default {
    getUserInfo() {
        return AUTH_AXIOS.get('/user_info');
    },
    updateUserInfo(userData) {
        return AUTH_AXIOS.put('/user_info',
            JSON.stringify(userData),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    }
}
